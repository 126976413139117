import React, { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import "../styles/InspectionsPage.css";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";

Modal.setAppElement('#root');

const NewInspection = () => {
  // State declarations
  const [templates, setTemplates] = useState([]);
  const [yards, setYards] = useState([]);
  const [gms, setGms] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateType, setTemplateType] = useState('standard');
  const [sections, setSections] = useState([]);
  const [responses, setResponses] = useState({});
  const [scores, setScores] = useState({});
  const [sectionScores, setSectionScores] = useState({});
  const [scoringDetails, setScoringDetails] = useState({
    totalEarned: 0,
    totalPossible: 0,
    sectionBreakdown: {}
  });
  const [userId, setUserId] = useState(null);
  const [baseData, setBaseData] = useState({
    date: "",
    yard_id: "",
    gm_id: "",
    lastVisitDate: "",
    lastVisitRating: "",
    currentVisitRating: "",
    template_id: "",
    totalScore: 0,
    totalPossibleScore: 0,
    lastTotalScore: null,
    lastPossibleScore: null
  });
  const [currentRating, setCurrentRating] = useState(0);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Utility functions
  const detectDeviceType = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(userAgent) 
      ? "mobile" 
      : "desktop";
  };

  // Initialize data on component mount
  useEffect(() => {
    const initializeData = async () => {
      await Promise.all([
        fetchTemplates(),
        fetchYards(),
        fetchGms(),
        fetchUserId()
      ]);
      setDeviceType(detectDeviceType());
    };

    initializeData();
  }, []);

  // Fetch last inspection when yard or template changes
  useEffect(() => {
    if (baseData.yard_id && baseData.template_id) {
      fetchLastInspection(baseData.yard_id, baseData.template_id);
    }
  }, [baseData.yard_id, baseData.template_id]);

  // Update scores when responses change
  useEffect(() => {
    if (templateType === 'scored') {
      updateScores();
    }
  }, [responses]);
  // Data fetching functions
  const fetchUserId = async () => {
    try {
      const { data: { user }, error } = await supabase.auth.getUser();
      if (error) throw error;
      if (user) setUserId(user.id);
    } catch (error) {
      console.error("Error fetching user:", error);
      setError("Error fetching user information.");
    }
  };

  const fetchTemplates = async () => {
    try {
      const { data, error } = await supabase
        .from("inspection_templates")
        .select("id, name, template_type");
      if (error) throw error;
      setTemplates(data);
    } catch (error) {
      setError("Failed to fetch templates.");
      console.error(error);
    }
  };

  const fetchYards = async () => {
    try {
      const { data, error } = await supabase
        .from("yards")
        .select("id, name");
      if (error) throw error;
      setYards(data);
    } catch (error) {
      setError("Failed to fetch yards.");
      console.error(error);
    }
  };

  const fetchGms = async () => {
    try {
      const { data, error } = await supabase
        .from("employees")
        .select("id, name")
        .eq("position", "GM");
      if (error) throw error;
      setGms(data);
    } catch (error) {
      setError("Failed to fetch GMs.");
      console.error(error);
    }
  };

  const fetchLastInspection = async (yardId, templateId) => {
    if (!yardId || !templateId) return;

    try {
      const { data, error } = await supabase
        .from("inspections")
        .select("date, current_visit_rating, total_score, total_possible_score")
        .eq("yard_id", yardId)
        .eq("template_id", templateId)
        .order("created_at", { ascending: false })
        .limit(1);

      if (error) throw error;

      if (data?.length > 0) {
        setBaseData(prev => ({
          ...prev,
          lastVisitDate: data[0].date,
          lastVisitRating: data[0].current_visit_rating,
          lastTotalScore: data[0].total_score,
          lastPossibleScore: data[0].total_possible_score
        }));
      } else {
        setBaseData(prev => ({
          ...prev,
          lastVisitDate: "",
          lastVisitRating: "",
          lastTotalScore: null,
          lastPossibleScore: null
        }));
      }
    } catch (error) {
      console.error("Failed to fetch last inspection:", error);
      setError("Failed to fetch last inspection data.");
    }
  };

  const fetchSections = async (templateId) => {
    try {
      const { data, error } = await supabase
        .from('sections')
        .select(`
          id,
          title,
          score_allocation,
          template_id,
          questions (
            id,
            text,
            type,
            allow_photo,
            allow_comments,
            photo_required,
            description,
            options
          )
        `)
        .eq('template_id', templateId);

      if (error) throw error;
      
      const processedData = data?.map(section => ({
        ...section,
        questions: section.questions.map(q => ({
          ...q,
          options: typeof q.options === 'string' ? JSON.parse(q.options) : q.options
        }))
      }));

      setSections(processedData || []);
      
      if (templateType === 'scored') {
        const initialScores = {};
        processedData?.forEach(section => {
          initialScores[section.id] = {
            earned: 0,
            possible: section.score_allocation || 0
          };
        });
        setSectionScores(initialScores);
      }
    } catch (error) {
      setError("Failed to fetch sections and questions.");
      console.error(error);
    }
  };

  // Scoring calculation functions
  const calculateQuestionScore = (questionId, response) => {
    const question = sections
      .flatMap(s => s.questions)
      .find(q => q.id === questionId);

    if (!question?.options) return 0;

    const options = Array.isArray(question.options) 
      ? question.options 
      : JSON.parse(question.options);

    const selectedOption = options.find(opt => opt.text === response);
    return selectedOption?.points || 0;
  };

  const calculateSectionScore = (sectionId) => {
    const section = sections.find(s => s.id === sectionId);
    if (!section || templateType !== 'scored') {
      return { earned: 0, possible: 0 };
    }

    let totalPointsForSection = 0;
    const sectionQuestions = section.questions || [];
    const sectionAllocation = section.score_allocation || 0;

    sectionQuestions.forEach(question => {
      const response = responses[question.id];
      if (response?.response) {
        const questionPoints = response.points || 
                           calculateQuestionScore(question.id, response.response);
        totalPointsForSection += questionPoints;
      }
    });

    return {
      earned: Math.min(totalPointsForSection, sectionAllocation),
      possible: sectionAllocation
    };
  };

  const updateScores = () => {
    if (templateType !== 'scored') return;

    const newSectionScores = {};
    let totalEarned = 0;
    let totalPossible = 0;

    sections.forEach(section => {
      const sectionScore = calculateSectionScore(section.id);
      newSectionScores[section.id] = sectionScore;
      totalEarned += sectionScore.earned;
      totalPossible += sectionScore.possible;
    });

    setSectionScores(newSectionScores);
    setScoringDetails({
      totalEarned,
      totalPossible,
      sectionBreakdown: newSectionScores
    });

    setBaseData(prev => ({
      ...prev,
      totalScore: totalEarned,
      totalPossibleScore: totalPossible
    }));
  };
  // Event Handlers
  const handleResponseChange = (sectionId, questionId, value) => {
    const points = templateType === 'scored' ? calculateQuestionScore(questionId, value) : null;
    
    setResponses(prevResponses => {
      const updatedResponses = {
        ...prevResponses,
        [questionId]: {
          ...prevResponses[questionId],
          response: value,
          section_id: sectionId,
          points
        }
      };

      setValidationErrors(prev => ({
        ...prev,
        [questionId]: null,
      }));

      if (templateType === 'scored') {
        setTimeout(updateScores, 0);
      } else {
        const newRating = calculateRating(updatedResponses);
        setCurrentRating(newRating);
        setBaseData(prev => ({
          ...prev,
          currentVisitRating: getRatingText(newRating)
        }));
      }

      return updatedResponses;
    });
  };

  const handlePhotoChange = (sectionId, questionId, file) => {
    setResponses(prev => ({
      ...prev,
      [questionId]: {
        ...prev[questionId],
        image: file,
        section_id: sectionId,
      },
    }));

    setValidationErrors(prev => ({
      ...prev,
      [questionId]: null,
    }));
  };

  const handleCommentChange = (sectionId, questionId, comments) => {
    setResponses(prev => ({
      ...prev,
      [questionId]: {
        ...prev[questionId],
        comments: comments,
        section_id: sectionId,
      },
    }));
  };

  const startInspection = async (templateId) => {
    setError("");
    const template = templates.find(t => t.id === templateId);
    setTemplateType(template.template_type || 'standard');
    setSelectedTemplate(templateId);
    setBaseData(prev => ({
      ...prev,
      date: new Date().toISOString().split("T")[0],
      template_id: templateId,
    }));
    await fetchSections(templateId);
  };

  const handleBaseDataChange = (e) => {
    const { name, value } = e.target;
    setBaseData(prev => ({ ...prev, [name]: value }));

    if (name === "yard_id" && value) {
      fetchLastInspection(value, baseData.template_id);
    }
  };

  const calculateRating = (currentResponses) => {
    if (templateType === 'scored') return null;

    const scores = Object.values(currentResponses)
      .map(response => {
        switch (response.response) {
          case "Below Expectations": return 1;
          case "Meets Expectations": return 2;
          case "Exceeds Expectations": return 3;
          default: return null;
        }
      })
      .filter(score => score !== null);

    if (scores.length === 0) return 0;
    return parseFloat((scores.reduce((sum, score) => sum + score, 0) / scores.length).toFixed(2));
  };

  const getRatingText = (rating) => {
    if (!rating) return "";
    if (rating < 2) return "Below Expectations";
    if (rating >= 2 && rating <= 2.75) return "Meets Expectations";
    return "Exceeds Expectations";
  };

  const validateForm = () => {
    if (!baseData.date || !baseData.yard_id || !baseData.gm_id) {
      setError("Please fill out all required fields (date, yard, GM).");
      return false;
    }

    if (!selectedTemplate) {
      setError("Please select a template.");
      return false;
    }

    if (templateType === 'scored') {
      const unansweredQuestions = sections.flatMap(section => 
        section.questions.filter(question => !responses[question.id]?.response)
      );
      
      if (unansweredQuestions.length > 0) {
        setError("Please answer all questions in the scored template.");
        return false;
      }
    } else {
      const errors = {};
      sections.forEach(section => {
        section.questions.forEach(question => {
          const response = responses[question.id];
          if (response?.response === "Below Expectations" && !response.image) {
            errors[question.id] = "Photo required for Below Expectations";
          }
        });
      });
      setValidationErrors(errors);
      if (Object.keys(errors).length > 0) {
        setError("Please provide photos for all Below Expectations responses.");
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);

    try {
      const inspectionData = {
        template_id: selectedTemplate,
        user_id: userId,
        date: baseData.date,
        yard_id: baseData.yard_id,
        gm_id: baseData.gm_id,
        last_visit_date: baseData.lastVisitDate || null,
        last_visit_rating: baseData.lastVisitRating || null,
        current_visit_rating: templateType === 'standard' ? baseData.currentVisitRating : null,
        total_score: templateType === 'scored' ? scoringDetails.totalEarned : null,
        total_possible_score: templateType === 'scored' ? scoringDetails.totalPossible : null,
        device_type: deviceType,
      };

      const { data: inspection, error: inspectionError } = await supabase
        .from("inspections")
        .insert([inspectionData])
        .select();

      if (inspectionError) throw inspectionError;

      const inspectionId = inspection[0].id;

      if (templateType === 'scored') {
        const sectionScorePromises = Object.entries(sectionScores).map(([sectionId, scores]) => {
          return supabase
            .from("section_scores")
            .insert([{
              inspection_id: inspectionId,
              section_id: sectionId,
              earned_points: scores.earned,
              possible_points: scores.possible
            }]);
        });

        await Promise.all(sectionScorePromises);
      }

      for (const questionId in responses) {
        const response = responses[questionId];
        let imageUrl = null;

        if (response.image) {
          const filePath = `${inspectionId}/${questionId}`;
          const { error: uploadError } = await supabase.storage
            .from("inspection-images")
            .upload(filePath, response.image);

          if (uploadError) throw uploadError;

          const { data: signedUrlData } = await supabase.storage
            .from("inspection-images")
            .createSignedUrl(filePath, 60 * 60 * 24 * 365 * 10);

          imageUrl = signedUrlData.signedUrl;
        }

        const responseData = {
          inspection_id: inspectionId,
          question_id: questionId,
          response: response.response,
          image_url: imageUrl,
          comments: response.comments || '',
          section_id: response.section_id,
          user_id: userId,
          points_earned: templateType === 'scored' ? response.points : null
        };

        const { error: responseError } = await supabase
          .from("responses")
          .insert([responseData]);

        if (responseError) throw responseError;
      }

      setSuccess(true);
    } catch (error) {
      console.error("Failed to submit inspection:", error);
      setError(`Failed to submit inspection: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSuccessModal = () => {
    setSuccess(false);
    window.location.reload();
  };

  const openModal = (description) => {
    setModalContent(description);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  // Render
  return (
    <div className="inspection-container">
      {!selectedTemplate && (
        <div>
          <h2>Available Inspections</h2>
          <div className="template-list">
            {templates.map((template) => (
              <div key={template.id} className="template-item">
                <span>{template.name}</span>
                <span className="template-type-badge">
                  {template.template_type || 'standard'}
                </span>
                <button 
                  onClick={() => startInspection(template.id)} 
                  className="btn-primary"
                >
                  Start Inspection
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      {selectedTemplate && (
        <div className="form-container">
          <h3>Inspection Information</h3>
          <div className="form-section">
            <label>Date:</label>
            <input
              className="input-field"
              type="date"
              name="date"
              value={baseData.date}
              onChange={handleBaseDataChange}
              required
            />
            <label>Yard:</label>
            <select
              className="input-field"
              name="yard_id"
              value={baseData.yard_id}
              onChange={handleBaseDataChange}
              required
            >
              <option value="">Select Yard</option>
              {yards.map((yard) => (
                <option key={yard.id} value={yard.id}>
                  {yard.name}
                </option>
              ))}
            </select>
            <label>GM:</label>
            <select
              className="input-field"
              name="gm_id"
              value={baseData.gm_id}
              onChange={handleBaseDataChange}
              required
            >
              <option value="">Select GM</option>
              {gms.map((gm) => (
                <option key={gm.id} value={gm.id}>
                  {gm.name}
                </option>
              ))}
            </select>

            {templateType === 'standard' && (
              <>
                <label>Last Visit Date:</label>
                <input
                  className="input-field"
                  type="date"
                  name="lastVisitDate"
                  value={baseData.lastVisitDate}
                  readOnly
                />
                <label>Last Visit Rating:</label>
                <input
                  className="input-field"
                  type="text"
                  name="lastVisitRating"
                  value={baseData.lastVisitRating}
                  readOnly
                />
                <label>Current Visit Rating:</label>
                <input
                  className="input-field"
                  type="text"
                  name="currentVisitRating"
                  value={baseData.currentVisitRating}
                  readOnly
                />
              </>
            )}
          </div>

          {sections.length > 0 && (
            <form onSubmit={handleSubmit}>
              {sections.map((section) => (
                <div key={section.id} className="form-section">
                  <h3>{section.title}</h3>
                  {templateType === 'scored' && (
                    <div className="section-score-allocation">
                      Total Available Points: {section.score_allocation}
                    </div>
                  )}

                  {section.questions.map((question) => (
                    <div key={question.id} className="question-container">
                      <label>{question.text}</label>
                      
                      {templateType === 'scored' ? (
                        <select
                          className="input-field"
                          onChange={(e) =>
                            handleResponseChange(
                              section.id,
                              question.id,
                              e.target.value
                            )
                          }
                          value={responses[question.id]?.response || ''}
                          required
                        >
                          <option value="">Select an answer</option>
                          {question.options?.map((option, index) => (
                            <option key={index} value={option.text}>
                              {option.text} ({option.points} points)
                            </option>
                          ))}
                        </select>
                      ) : (
                        <select
                          className="input-field"
                          onChange={(e) =>
                            handleResponseChange(
                              section.id,
                              question.id,
                              e.target.value
                            )
                          }
                          value={responses[question.id]?.response || ''}
                          required={question.photo_required}
                        >
                          <option value="">Select a response</option>
                          <option value="Below Expectations">Below Expectations</option>
                          <option value="Meets Expectations">Meets Expectations</option>
                          <option value="Exceeds Expectations">Exceeds Expectations</option>
                        </select>
                      )}

                      <span>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            openModal(question.description);
                          }}
                          className="instructions-link"
                        >
                          Instructions
                        </a>
                      </span>

                      {(question.allow_photo || 
                        (templateType === 'standard' && 
                        responses[question.id]?.response === "Below Expectations")) && (
                        <div>
                          <input
                            className="input-field"
                            type="file"
                            accept="image/*"
                            capture="environment"
                            onChange={(e) =>
                              handlePhotoChange(
                                section.id,
                                question.id,
                                e.target.files[0]
                              )
                            }
                            required={responses[question.id]?.response === "Below Expectations"}
                          />
                          {validationErrors[question.id] && (
                            <p className="error">{validationErrors[question.id]}</p>
                          )}
                        </div>
                      )}

                      {question.allow_comments && (
                        <textarea
                          className="input-field"
                          placeholder="Add a comment..."
                          value={responses[question.id]?.comments || ''}
                          onChange={(e) =>
                            handleCommentChange(
                              section.id,
                              question.id,
                              e.target.value
                            )
                          }
                        />
                      )}
                    </div>
                  ))}
                </div>
              ))}
              <button type="submit" className="btn-submit" disabled={loading}>
                {loading ? 'Submitting...' : 'Submit Inspection'}
              </button>
            </form>
          )}
        </div>
      )}

      {error && <p className="error">{error}</p>}

      {success && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Success!</h2>
            <p style={{ marginBottom: "20px" }}>
              Inspection submitted successfully.
            </p>
            <button onClick={handleCloseSuccessModal} className="btn-primary">
              OK
            </button>
          </div>
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Instructions"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>Instructions</h2>
        <p>{modalContent}</p>
        <button onClick={closeModal} className="btn-primary">
          Close
        </button>
      </Modal>
    </div>
  );
};

export default NewInspection;
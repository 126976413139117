import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import '../styles/Login.css'; // Use the same Login.css for consistent styling

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    try {
      const { error: verifyError } = await supabase.auth.verifyOtp({
        email,
        token,
        type: 'recovery'
      });

      if (verifyError) throw verifyError;

      const { error: updateError } = await supabase.auth.updateUser({
        password: newPassword
      });

      if (updateError) throw updateError;

      setMessage('Password successfully reset!');
    } catch (error) {
      setMessage('Error: ' + error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Reset Password</h2>
        <form onSubmit={handleResetPassword}>
          <div className="form-group">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
              className="form-control"
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              value={token}
              onChange={(e) => setToken(e.target.value)}
              placeholder="Enter verification code"
              required
              className="form-control"
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Enter new password"
              required
              className="form-control"
            />
          </div>
          <button type="submit" className="btn-primary" disabled={isProcessing}>
            {isProcessing ? 'Resetting...' : 'Reset Password'}
          </button>
          {isProcessing && <p className="processing-message">Processing your request...</p>}
          {message && <p className="message">{message}</p>}
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
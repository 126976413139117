import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import '../styles/Login.css'; // Use the same Login.css for consistent styling

const ResetPasswordRequest = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const handleRequestCode = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/reset-password`,
      });
      
      if (error) throw error;
      setMessage('Verification code sent to your email. Please check your inbox and use the code to reset your password.');
    } catch (error) {
      setMessage('Error: ' + error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Reset Password Request</h2>
        <form onSubmit={handleRequestCode}>
          <div className="form-group">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
              className="form-control"
            />
          </div>
          <button type="submit" className="btn-primary" disabled={isProcessing}>
            {isProcessing ? 'Sending...' : 'Send Verification Code'}
          </button>
          {isProcessing && <p className="processing-message">Processing your request...</p>}
          {message && <p className="message">{message}</p>}
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordRequest;
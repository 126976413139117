import React, { useState, useEffect } from 'react';
import { supabase, supabaseAdmin } from '../supabaseClient';
import '../styles/AdminPage.css';

const AdminPage = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserName, setNewUserName] = useState('');
  const [newUserRole, setNewUserRole] = useState('');
  const [newUserPassword, setNewUserPassword] = useState('');
  const [editingUserId, setEditingUserId] = useState(null);
  const [editingUserEmail, setEditingUserEmail] = useState('');
  const [editingUserName, setEditingUserName] = useState('');
  const [editingUserRole, setEditingUserRole] = useState('');
  const [editingUserPassword, setEditingUserPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    fetchUsers();
    fetchRoles();
  }, []);

  const fetchUsers = async () => {
    try {
      const { data, error } = await supabase
        .from('users')
        .select('id, email, name, role');

      if (error) {
        setError('Failed to fetch users.');
      } else {
        setUsers(data);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Failed to fetch users.');
    }
  };

  const fetchRoles = async () => {
    try {
      const { data, error } = await supabase
        .from('roles')
        .select('id, role');

      if (error) {
        throw error;
      } else {
        setRoles(data);
      }
    } catch (error) {
      console.error('Error fetching roles:', error.message);
      setError('Failed to fetch roles.');
    }
  };

  const validatePassword = (password) => {
    if (!password) return true; // Allow empty password (no password change)
    return password.length >= 6;
  };

  const addUser = async () => {
    if (!newUserEmail || !newUserName || !newUserRole || !newUserPassword) {
      setError('Please fill out all fields: email, name, password, and role.');
      return;
    }

    if (!validatePassword(newUserPassword)) {
      setError('Password must be at least 6 characters long');
      return;
    }

    setError('');
    setSuccess('');

    try {
      const { data: newUser, error: createUserError } = await supabaseAdmin.auth.admin.createUser({
        email: newUserEmail,
        password: newUserPassword,
        email_confirm: true,
      });

      if (createUserError) {
        throw createUserError;
      }

      await supabase.from('users').insert([{
        id: newUser.user.id,
        email: newUserEmail,
        name: newUserName,
        role: newUserRole
      }]);

      fetchUsers();
      setNewUserEmail('');
      setNewUserName('');
      setNewUserRole('');
      setNewUserPassword('');
      setSuccess('User created successfully!');
    } catch (error) {
      console.error('Error creating user:', error);
      setError('Failed to create user.');
    }
  };

  const startEditingUser = (user) => {
    setEditingUserId(user.id);
    setEditingUserEmail(user.email);
    setEditingUserName(user.name);
    setEditingUserRole(user.role);
    setEditingUserPassword(''); // Reset password field when starting edit
  };

  const cancelEditing = () => {
    setEditingUserId(null);
    setEditingUserEmail('');
    setEditingUserName('');
    setEditingUserRole('');
    setEditingUserPassword('');
  };

  const saveUser = async () => {
    try {
      // Update user details in the users table
      const { error: updateError } = await supabase
        .from('users')
        .update({ 
          email: editingUserEmail, 
          name: editingUserName, 
          role: editingUserRole 
        })
        .eq('id', editingUserId);

      if (updateError) throw updateError;

      // If a new password is provided, update it
      if (editingUserPassword) {
        if (!validatePassword(editingUserPassword)) {
          setError('Password must be at least 6 characters long');
          return;
        }

        const { error: passwordError } = await supabaseAdmin.auth.admin.updateUserById(
          editingUserId,
          { password: editingUserPassword }
        );

        if (passwordError) throw passwordError;
      }

      fetchUsers();
      cancelEditing();
      setSuccess('User updated successfully!');
    } catch (error) {
      console.error('Error updating user:', error);
      setError('Failed to update user.');
    }
  };

  const deleteUser = async (userId) => {
    try {
      const { error: deleteUserError } = await supabaseAdmin.auth.admin.deleteUser(userId);
      if (deleteUserError) {
        throw deleteUserError;
      }

      await supabase.from('users').delete().eq('id', userId);

      fetchUsers();
      setSuccess('User deleted successfully!');
    } catch (error) {
      console.error('Error deleting user:', error);
      setError('Failed to delete user.');
    }
  };

  return (
    <div className="admin-container">
      <div className="admin-box">
        <h2>Admin Panel</h2>
        
        <div className="form-group">
          <h3>Create New User</h3>
          <input
            type="email"
            placeholder="Email"
            value={newUserEmail}
            onChange={(e) => setNewUserEmail(e.target.value)}
            className="form-control"
          />
          <input
            type="text"
            placeholder="Name"
            value={newUserName}
            onChange={(e) => setNewUserName(e.target.value)}
            className="form-control"
          />
          <input
            type="password"
            placeholder="Password"
            value={newUserPassword}
            onChange={(e) => setNewUserPassword(e.target.value)}
            className="form-control"
          />
          <select
            value={newUserRole}
            onChange={(e) => setNewUserRole(e.target.value)}
            className="form-control"
          >
            <option value="">Select Role</option>
            {roles.map((role) => (
              <option key={role.id} value={role.id}>
                {role.role}
              </option>
            ))}
          </select>
          <button onClick={addUser} className="btn-primary">Add User</button>
        </div>

        {error && <p className="error-text">{error}</p>}
        {success && <p className="success-text">{success}</p>}

        <div className="table-container">
          <h3>Users List</h3>
          <table className="users-table">
            <thead>
              <tr>
                <th>Email</th>
                <th>Name</th>
                <th>Role</th>
                <th>Password</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>
                    {editingUserId === user.id ? (
                      <input
                        type="email"
                        value={editingUserEmail}
                        onChange={(e) => setEditingUserEmail(e.target.value)}
                        className="form-control"
                      />
                    ) : (
                      user.email
                    )}
                  </td>
                  <td>
                    {editingUserId === user.id ? (
                      <input
                        type="text"
                        value={editingUserName}
                        onChange={(e) => setEditingUserName(e.target.value)}
                        className="form-control"
                      />
                    ) : (
                      user.name
                    )}
                  </td>
                  <td>
                    {editingUserId === user.id ? (
                      <select
                        value={editingUserRole}
                        onChange={(e) => setEditingUserRole(e.target.value)}
                        className="form-control"
                      >
                        {roles.map((role) => (
                          <option key={role.id} value={role.id}>
                            {role.role}
                          </option>
                        ))}
                      </select>
                    ) : (
                      roles.find(r => r.id === user.role)?.role || 'Unknown'
                    )}
                  </td>
                  <td>
                    {editingUserId === user.id && (
                      <input
                        type="password"
                        placeholder="New Password (optional)"
                        value={editingUserPassword}
                        onChange={(e) => setEditingUserPassword(e.target.value)}
                        className="form-control"
                      />
                    )}
                  </td>
                  <td>
                    {editingUserId === user.id ? (
                      <>
                        <button className="btn-primary" onClick={saveUser}>Save</button>
                        <button className="btn-secondary" onClick={cancelEditing}>Cancel</button>
                      </>
                    ) : (
                      <>
                        <button className="btn-primary" onClick={() => startEditingUser(user)}>Edit</button>
                        <button className="btn-secondary" onClick={() => deleteUser(user.id)}>Delete</button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
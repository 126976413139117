import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext'; // Use AuthContext
import { Link } from 'react-router-dom'; // Use Link for navigation to reset page
import '../styles/Login.css';  // Custom CSS for this component

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      await login(email, password);
      window.location.href = 'new-inspection';  // Redirect after login
    } catch (error) {
      setError('Login failed. Please check your credentials.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>JBH Multi-Point Site Inspection</h2>
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="form-control"
            />
          </div>
          <button type="submit" className="btn-primary">Login</button>
          {error && <p className="error-text">{error}</p>}
        </form>

        {/* Password Reset Link */}
        <div className="password-reset">
          <Link to="/request-reset" className="forgot-password-link">
            Forgot Password?
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
import React from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import logo from '../assets/images/logo.png';

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 10,
    fontFamily: 'Helvetica',
  },
  headerContainer: {
    flexDirection: 'row',
    marginBottom: 8,
    borderWidth: 1,
    borderColor: '#000',
    borderRadius: 5,
  },
  logoContainer: {
    width: '20%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 2,
  },
  logo: {
    width: '80%',
    height: 'auto',
  },
  headerInfoContainer: {
    flexDirection: 'column',
    width: '65%',
    justifyContent: 'space-around',
    padding: 2,
  },
  headerRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 2,
  },
  headerField: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 3,
    paddingVertical: 2,
  },
  headerLabel: {
    fontWeight: 'bold',
    fontSize: 10,
    color: '#444',
  },
  headerValue: {
    fontSize: 10,
    marginLeft: 3,
    color: '#000',
  },
  scoreContainer: {
    width: '15%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 2,
    borderLeftWidth: 1,
    borderColor: '#000',
    backgroundColor: '#f8f8f8',
  },
  scoreHeader: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 10,
    marginBottom: 4,
    color: '#444',
  },
  scoreText: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 11,
    color: '#000',
  },
  scorePercentage: {
    fontSize: 9,
    textAlign: 'center',
    color: '#666',
    marginTop: 2,
  },
  pointsText: {
    fontSize: 9,
    textAlign: 'center',
    color: '#666',
    marginTop: 2,
  },
  summaryContainer: {
    marginTop: 15,
    marginBottom: 10,
    padding: 8,
    backgroundColor: '#f8f8f8',
    borderRadius: 4,
  },
  summaryTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 6,
    color: '#444',
  },
  summaryRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 4,
  },
  summaryLabel: {
    flex: 2,
    fontSize: 10,
  },
  summaryValue: {
    flex: 1,
    fontSize: 10,
    textAlign: 'right',
  },
  sectionContainer: {
    marginTop: 10,
    marginBottom: 5,
    border: 1,
    borderColor: '#444',
    borderRadius: 4,
  },
  sectionHeader: {
    backgroundColor: '#444',
    padding: 6,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sectionTitle: {
    color: '#fff',
    fontSize: 12,
    fontWeight: 'bold',
    flex: 2,
  },
  sectionScore: {
    color: '#fff',
    fontSize: 10,
    flex: 1,
    textAlign: 'right',
  },
  questionContainer: {
    padding: 6,
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
  },
  questionRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 4,
  },
  questionText: {
    flex: 3,
    fontSize: 10,
  },
  responseText: {
    flex: 2,
    fontSize: 10,
  },
  pointsEarned: {
    width: '15%',
    fontSize: 10,
    textAlign: 'right',
  },
  commentsText: {
    fontSize: 9,
    color: '#666',
    fontStyle: 'italic',
    marginTop: 2,
  },
  totalScoreContainer: {
    marginTop: 10,
    marginBottom: 15,
    padding: 10,
    backgroundColor: '#f8f8f8',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#ddd',
  },
  totalScoreTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
    color: '#333',
  },
  totalScoreValue: {
    fontSize: 12,
    marginBottom: 2,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 9,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: '#666',
  },
  noDataText: {
    fontSize: 12,
    color: '#666',
    textAlign: 'center',
    marginTop: 20,
  }
});

const InspectionPrint = ({
  inspection,
  inspectionDetails,
  getYardName,
  getInspectorName,
  getGmName,
  formatDate,
}) => {
  const isScored = inspection.template?.template_type === 'scored';

  // Calculate scores and group details by section
  const sectionSummaries = inspectionDetails.reduce((acc, detail) => {
    const sectionTitle = detail.section_title;
    if (!acc[sectionTitle]) {
      acc[sectionTitle] = {
        points: 0,
        possiblePoints: 0,
        questions: []
      };
    }
    acc[sectionTitle].points += detail.points_earned || 0;
    acc[sectionTitle].possiblePoints += detail.points_possible || 0;
    acc[sectionTitle].questions.push(detail);
    return acc;
  }, {});

  const totalPoints = Object.values(sectionSummaries).reduce(
    (sum, section) => sum + section.points, 0
  );
  const totalPossiblePoints = Object.values(sectionSummaries).reduce(
    (sum, section) => sum + section.possiblePoints, 0
  );
  const totalPercentage = totalPossiblePoints > 0 
    ? ((totalPoints / totalPossiblePoints) * 100).toFixed(1)
    : '0';

  const renderHeader = () => (
    <View style={styles.headerContainer} fixed>
      <View style={styles.logoContainer}>
        <Image style={styles.logo} src={logo} />
      </View>
      <View style={styles.headerInfoContainer}>
        <View style={styles.headerRow}>
          <View style={styles.headerField}>
            <Text style={styles.headerLabel}>Date:</Text>
            <Text style={styles.headerValue}>{formatDate(inspection.date)}</Text>
          </View>
          <View style={styles.headerField}>
            <Text style={styles.headerLabel}>Inspector:</Text>
            <Text style={styles.headerValue}>{getInspectorName(inspection)}</Text>
          </View>
        </View>
        <View style={styles.headerRow}>
          <View style={styles.headerField}>
            <Text style={styles.headerLabel}>Yard:</Text>
            <Text style={styles.headerValue}>{getYardName(inspection.yard_id)}</Text>
          </View>
          <View style={styles.headerField}>
            <Text style={styles.headerLabel}>GM:</Text>
            <Text style={styles.headerValue}>{getGmName(inspection)}</Text>
          </View>
        </View>
      </View>
      {isScored ? (
        <View style={styles.scoreContainer}>
          <Text style={styles.scoreHeader}>Total Score</Text>
          <Text style={styles.scoreText}>
            {totalPoints}/{totalPossiblePoints}
          </Text>
          <Text style={styles.scorePercentage}>
            ({totalPercentage}%)
          </Text>
          <Text style={styles.pointsText}>
            Points Earned
          </Text>
        </View>
      ) : (
        <View style={styles.scoreContainer}>
          <Text style={styles.scoreHeader}>Current Rating</Text>
          <Text style={styles.scoreText}>
            {inspection.current_visit_rating}
          </Text>
        </View>
      )}
    </View>
  );

  const renderSectionSummary = () => {
    if (!isScored) return null;

    return (
      <View style={styles.summaryContainer}>
        <Text style={styles.summaryTitle}>Section Summary</Text>
        {Object.entries(sectionSummaries).map(([title, summary], index) => (
          <View key={index} style={styles.summaryRow}>
            <Text style={styles.summaryLabel}>{title}:</Text>
            <Text style={styles.summaryValue}>
              {summary.points}/{summary.possiblePoints}
              ({(summary.points / summary.possiblePoints * 100).toFixed(1)}%)
            </Text>
          </View>
        ))}
      </View>
    );
  };

  const renderSections = () => (
    <>
      {Object.entries(sectionSummaries).map(([sectionTitle, summary], index) => (
        <View key={index} style={styles.sectionContainer}>
          <View style={styles.sectionHeader}>
            <Text style={styles.sectionTitle}>{sectionTitle}</Text>
            {isScored && (
              <Text style={styles.sectionScore}>
                {summary.points}/{summary.possiblePoints} 
                ({(summary.points / summary.possiblePoints * 100).toFixed(1)}%)
              </Text>
            )}
          </View>
          {summary.questions.map((detail, qIndex) => (
            <View key={qIndex} style={styles.questionContainer}>
              <View style={styles.questionRow}>
                <Text style={styles.questionText}>{detail.question_text}</Text>
                <Text style={styles.responseText}>
                  {detail.response_text}
                </Text>
                {isScored && (
                  <Text style={styles.pointsEarned}>
                    {detail.points_earned}/{detail.points_possible}
                  </Text>
                )}
              </View>
              {detail.response_comments && (
                <Text style={styles.commentsText}>
                  Comments: {detail.response_comments}
                </Text>
              )}
            </View>
          ))}
        </View>
      ))}
    </>
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {renderHeader()}
        {renderSectionSummary()}
        {renderSections()}
        <Text 
          style={styles.pageNumber} 
          render={({ pageNumber, totalPages }) => (
            `Page ${pageNumber} of ${totalPages}`
          )} 
          fixed
        />
      </Page>
    </Document>
  );
};

export default InspectionPrint;
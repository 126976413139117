import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import { supabase } from '../supabaseClient';
import '../styles/PreviousInspections.css';
import { PDFDownloadLink, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import InspectionPrint from '../components/InspectionPrint';

Modal.setAppElement('#root');

const PreviousInspections = () => {
  // State declarations
  const [inspections, setInspections] = useState([]);
  const [yards, setYards] = useState([]);
  const [inspectors, setInspectors] = useState([]);
  const [filteredInspections, setFilteredInspections] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedInspection, setSelectedInspection] = useState(null);
  const [inspectionDetails, setInspectionDetails] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [error, setError] = useState('');
  const [expandedSections, setExpandedSections] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initializeData = async () => {
      await Promise.all([
        fetchInspections(),
        fetchYards(),
        fetchInspectors()
      ]);
      setIsLoading(false);
    };

    initializeData();
  }, []);

  // Handle URL parameters
  useEffect(() => {
    const handleUrlParams = async () => {
      if (!isLoading && inspections.length > 0) {
        const params = new URLSearchParams(window.location.search);
        const inspectionId = params.get('inspectionId');
        if (inspectionId) {
          const inspection = inspections.find(i => i.id === inspectionId);
          if (inspection) {
            await handleViewDetails(inspection);
            window.history.replaceState({}, '', '/previous-inspections');
          }
        }
      }
    };

    handleUrlParams();
  }, [isLoading, inspections]);

  // Main data fetching function with improved score calculations
  const fetchInspections = async () => {
    try {
      const { data: inspectionsData, error: inspectionsError } = await supabase
        .from('inspections')
        .select(`
          *,
          inspector:user_id (id, name),
          gm:gm_id (id, name),
          template:template_id (id, name, template_type),
          section_scores (
            section_id,
            earned_points,
            possible_points,
            percentage
          )
        `)
        .order('date', { ascending: false });
  
      if (inspectionsError) throw inspectionsError;
  
      // Process inspections and calculate scores
      const processedInspections = inspectionsData.map(inspection => {
        if (inspection.template?.template_type === 'scored') {
          const sectionScores = inspection.section_scores || [];
          const totalEarned = sectionScores.reduce((sum, score) => 
            sum + Number(score.earned_points), 0
          );
          const totalPossible = sectionScores.reduce((sum, score) => 
            sum + Number(score.possible_points), 0
          );
  
          return {
            ...inspection,
            total_score: totalEarned,
            total_possible_score: totalPossible,
            percentage: totalPossible > 0 ? ((totalEarned / totalPossible) * 100).toFixed(1) : '0'
          };
        }
        return inspection;
      });
  
      setInspections(processedInspections);
      setFilteredInspections(processedInspections);
    } catch (error) {
      console.error('Error fetching inspections:', error);
      setError('Failed to load inspections.');
    }
  };
  const fetchYards = async () => {
    try {
      const { data, error } = await supabase.from('yards').select('*');
      if (error) throw error;
      setYards(data);
    } catch (error) {
      setError('Failed to load yards.');
    }
  };

  const fetchInspectors = async () => {
    try {
      const { data, error } = await supabase.from('employees').select('*');
      if (error) throw error;
      setInspectors(data);
    } catch (error) {
      setError('Failed to load inspectors.');
    }
  };

  // Enhanced inspection details fetching with better score handling
  const fetchInspectionDetails = async (inspectionId) => {
    try {
      // Fetch responses and section scores in parallel
      const [responseData, sectionScoresData] = await Promise.all([
        supabase
          .from('responses')
          .select(`
            response,
            comments,
            image_url,
            points_earned,
            question:questions!fk_question (
              id,
              text,
              type,
              allow_photo,
              allow_comments,
              options,
              section:sections!questions_section_id_fkey (
                id,
                title,
                score_allocation
              )
            )
          `)
          .eq('inspection_id', inspectionId),
        
        supabase
          .from('section_scores')
          .select(`
            section_id,
            earned_points,
            possible_points,
            percentage,
            section:section_id(
              id,
              title
            )
          `)
          .eq('inspection_id', inspectionId)
      ]);

      if (responseData.error) throw responseData.error;
      if (sectionScoresData.error) throw sectionScoresData.error;

      // Process section scores
      const sectionScores = sectionScoresData.data.reduce((acc, score) => {
        if (score.section) {
          acc[score.section.title] = {
            earned: Number(score.earned_points),
            max: Number(score.possible_points),
            percentage: score.percentage || (score.possible_points > 0 
              ? ((score.earned_points / score.possible_points) * 100).toFixed(1)
              : '0'),
            sectionId: score.section_id
          };
        }
        return acc;
      }, {});

      // Process responses with enhanced score tracking
      const details = responseData.data.map((response) => {
        const sectionTitle = response.question?.section?.title || 'Unknown Section';
        const pointsEarned = Number(response.points_earned) || 0;
        const scoreAllocation = response.question?.section?.score_allocation || 0;
        
        // Update section scores if not already present
        if (!sectionScores[sectionTitle]) {
          sectionScores[sectionTitle] = {
            earned: 0,
            max: scoreAllocation,
            percentage: '0',
            sectionId: response.question?.section?.id
          };
        }

        return {
          section_id: response.question?.section?.id,
          section_title: sectionTitle,
          section_score_allocation: scoreAllocation,
          question_id: response.question?.id,
          question_text: response.question?.text || 'Unknown Question',
          response_text: response.response,
          response_comments: response.comments,
          allow_comments: response.question?.allow_comments || false,
          response_image_url: response.image_url,
          points_earned: pointsEarned,
          points_possible: response.question?.options ? Math.max(
            ...response.question.options.map(opt => Number(opt.points) || 0)
          ) : 0,
          options: response.question?.options || []
        };
      });

      return {
        details,
        sectionScores
      };
    } catch (error) {
      console.error('Error fetching inspection details:', error);
      setError('Failed to load inspection details.');
      return { details: [], sectionScores: {} };
    }
  };

  // PDF Generation Component with fixed functionality
  const DownloadPDFButton = ({ inspection }) => {
    const [loading, setLoading] = useState(false);

    const handleDownload = async () => {
      setLoading(true);
      try {
        const { details, sectionScores } = await fetchInspectionDetails(inspection.id);
        const processedInspection = {
          ...inspection,
          sectionScores
        };

        const doc = (
          <InspectionPrint
            inspection={processedInspection}
            inspectionDetails={details}
            getYardName={getYardName}
            getInspectorName={getInspectorName}
            getGmName={getGmName}
            formatDate={formatDate}
          />
        );

        const asPdf = pdf();
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        const fileName = `InspectionReport_${formatDate(inspection.date)}_${getYardName(inspection.yard_id)}.pdf`;
        saveAs(blob, fileName);
      } catch (error) {
        console.error('Error generating PDF:', error);
        setError('Failed to generate PDF.');
      }
      setLoading(false);
    };

    return (
      <button 
        className="button" 
        onClick={handleDownload} 
        disabled={loading}
      >
        {loading ? 'Generating PDF...' : 'PDF'}
      </button>
    );
  };

  // Utility functions
  const formatDate = useCallback((dateString) => {
    const date = new Date(dateString);
    return `${String(date.getUTCMonth() + 1).padStart(2, '0')}-${String(date.getUTCDate()).padStart(2, '0')}-${date.getUTCFullYear()}`;
  }, []);

  const getYardName = useCallback((yard_id) => {
    const yard = yards.find((y) => y.id === yard_id);
    return yard ? yard.name : 'Unknown Yard';
  }, [yards]);

  const getInspectorName = useCallback((inspection) => {
    return inspection.inspector ? inspection.inspector.name : 'Unknown Inspector';
  }, []);

  const getGmName = useCallback((inspection) => {
    return inspection.gm ? inspection.gm.name : 'Unknown GM';
  }, []);
  // Search and filter functionality
  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    
    const filteredData = inspections.filter((inspection) => {
      const yardName = getYardName(inspection.yard_id).toLowerCase();
      const inspectorName = getInspectorName(inspection).toLowerCase();
      const inspectionType = inspection.template ? inspection.template.name.toLowerCase() : '';
      const currentVisitRating = inspection.current_visit_rating
        ? inspection.current_visit_rating.toLowerCase()
        : '';
      const score = inspection.total_score 
        ? `${inspection.total_score}/${inspection.total_possible_score}`.toLowerCase()
        : '';
        
      return (
        yardName.includes(searchTerm) ||
        inspectorName.includes(searchTerm) ||
        inspectionType.includes(searchTerm) ||
        currentVisitRating.includes(searchTerm) ||
        score.includes(searchTerm)
      );
    });
    
    setFilteredInspections(filteredData);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    const sortedInspections = [...filteredInspections].sort((a, b) => {
      let aValue = a[key];
      let bValue = b[key];

      // Handle nested properties
      if (key.includes('.')) {
        const keys = key.split('.');
        aValue = keys.reduce((obj, k) => obj?.[k], a);
        bValue = keys.reduce((obj, k) => obj?.[k], b);
      }

      // Handle score sorting
      if (key === 'total_score') {
        aValue = (a.total_score || 0) / (a.total_possible_score || 1);
        bValue = (b.total_score || 0) / (b.total_possible_score || 1);
      }

      if (aValue < bValue) return direction === 'ascending' ? -1 : 1;
      if (aValue > bValue) return direction === 'ascending' ? 1 : -1;
      return 0;
    });

    setFilteredInspections(sortedInspections);
    setSortConfig({ key, direction });
  };

  // View details handler
  const handleViewDetails = async (inspection) => {
    try {
      const { details, sectionScores } = await fetchInspectionDetails(inspection.id);
      
      // Calculate total points for this inspection
      const totalPoints = details.reduce((sum, detail) => sum + (detail.points_earned || 0), 0);
      const totalPossiblePoints = details.reduce((sum, detail) => sum + (detail.points_possible || 0), 0);
      
      setInspectionDetails(details);
      setSelectedInspection({
        ...inspection,
        sectionScores,
        calculatedTotalPoints: totalPoints,
        calculatedTotalPossiblePoints: totalPossiblePoints
      });

      const initialExpandedSections = {};
      details.forEach((detail) => {
        initialExpandedSections[detail.section_title] = true;
      });
      setExpandedSections(initialExpandedSections);
    } catch (error) {
      console.error('Error loading inspection details:', error);
      setError('Failed to load inspection details');
    }
  };

  const toggleSection = (sectionTitle) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [sectionTitle]: !prevState[sectionTitle],
    }));
  };

  const groupInspectionsByType = useCallback((inspections) => {
    return inspections.reduce((acc, inspection) => {
      const type = inspection.template ? inspection.template.name : 'Unknown Type';
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(inspection);
      return acc;
    }, {});
  }, []);

  const getSortArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '▲' : '▼';
    }
    return '';
  };

  // Helper function to group details by section
  const groupDetailsBySection = useCallback((details) => {
    return details.reduce((acc, detail) => {
      const sectionTitle = detail.section_title || 'Unknown Section';
      if (!acc[sectionTitle]) {
        acc[sectionTitle] = {
          details: [],
          totalPoints: 0,
          totalPossiblePoints: 0
        };
      }
      acc[sectionTitle].details.push(detail);
      acc[sectionTitle].totalPoints += detail.points_earned || 0;
      acc[sectionTitle].totalPossiblePoints += detail.points_possible || 0;
      return acc;
    }, {});
  }, []);

  // Calculate section percentage
  const calculateSectionPercentage = useCallback((earned, possible) => {
    if (!possible) return '0';
    return ((earned / possible) * 100).toFixed(1);
  }, []);
  // Loading state
  if (isLoading) {
    return <div className="loading">Loading inspections...</div>;
  }

  const groupedInspections = groupInspectionsByType(filteredInspections);

  return (
    <div className="page-container">
      <h2 className="main-heading">Previous Inspections</h2>

      {error && <p className="error">{error}</p>}

      <input
        type="text"
        placeholder="Search by yard, inspector, inspection type, rating, or score"
        value={searchTerm}
        onChange={handleSearch}
        className="search-bar"
      />

      {Object.entries(groupedInspections).map(([type, inspections]) => {
        const isScored = type.toLowerCase().includes('scored');
        return (
          <div key={type} className="inspection-group">
            <h3>{type}</h3>
            <div className="table-responsive">
              <table className="inspections-table">
                <thead>
                  <tr>
                    <th onClick={() => handleSort('date')}>Date {getSortArrow('date')}</th>
                    <th onClick={() => handleSort('yard_id')}>Yard {getSortArrow('yard_id')}</th>
                    <th onClick={() => handleSort('inspector.name')}>Inspector {getSortArrow('inspector.name')}</th>
                    <th onClick={() => handleSort('gm.name')}>GM {getSortArrow('gm.name')}</th>
                    <th onClick={() => handleSort(isScored ? 'total_score' : 'current_visit_rating')}>
                      {isScored ? 'Score' : 'Rating'} {getSortArrow(isScored ? 'total_score' : 'current_visit_rating')}
                    </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {inspections.length > 0 ? (
                    inspections.map((inspection) => (
                      <tr key={inspection.id}>
                        <td>{formatDate(inspection.date)}</td>
                        <td>{getYardName(inspection.yard_id)}</td>
                        <td>{getInspectorName(inspection)}</td>
                        <td>{getGmName(inspection)}</td>
                        <td>
                          {isScored ? (
                            <div className="score-cell">
                              {`${inspection.total_score || 0}/${inspection.total_possible_score || 0}`}
                              <span className="score-percentage">
                                {inspection.total_possible_score ? 
                                  ` (${inspection.percentage}%)` : 
                                  ''}
                              </span>
                            </div>
                          ) : (
                            inspection.current_visit_rating
                          )}
                        </td>
                        <td className="actions">
                          <div className="action-buttons">
                            <button
                              className="button"
                              onClick={() => handleViewDetails(inspection)}
                            >
                              View
                            </button>
                            <DownloadPDFButton inspection={inspection} />
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">No inspections found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        );
      })}

      {selectedInspection && inspectionDetails && (
        <Modal
          isOpen={!!selectedInspection}
          onRequestClose={() => setSelectedInspection(null)}
          contentLabel="Inspection Details"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <h3 className="modal-heading">Inspection Details</h3>
          
          <div className="inspection-header">
            <p><strong>Date:</strong> {formatDate(selectedInspection.date)}</p>
            <p><strong>Yard:</strong> {getYardName(selectedInspection.yard_id)}</p>
            <p><strong>Inspector:</strong> {getInspectorName(selectedInspection)}</p>
            <p><strong>GM:</strong> {getGmName(selectedInspection)}</p>
            <p><strong>Inspection Type:</strong> {selectedInspection.template?.name}</p>
            {(() => {
              const isScored = selectedInspection.template?.name?.toLowerCase().includes('scored');
              if (isScored) {
                return (
                  <div className="total-score">
                    <p><strong>Total Score:</strong> {selectedInspection.total_score}/{selectedInspection.total_possible_score} 
                    ({selectedInspection.percentage}%)</p>
                    <p><strong>Total Points:</strong> {selectedInspection.calculatedTotalPoints}/{selectedInspection.calculatedTotalPossiblePoints}</p>
                  </div>
                );
              }
              return (
                <p><strong>Current Visit Rating:</strong> {selectedInspection.current_visit_rating}</p>
              );
            })()}
          </div>

          {selectedInspection.template?.name?.toLowerCase().includes('scored') && (
            <div className="scores-summary">
              <h4>Section Scores</h4>
              {Object.entries(groupDetailsBySection(inspectionDetails)).map(([sectionTitle, sectionData]) => (
                <div key={sectionTitle} className="section-score">
                  <p>
                    <strong>{sectionTitle}:</strong> {sectionData.totalPoints}/{sectionData.totalPossiblePoints}
                    {` (${calculateSectionPercentage(sectionData.totalPoints, sectionData.totalPossiblePoints)}%)`}
                  </p>
                </div>
              ))}
            </div>
          )}

          {inspectionDetails.length > 0 ? (
            Object.entries(groupDetailsBySection(inspectionDetails)).map(
              ([sectionTitle, sectionData], index) => (
                <div key={index} className="section-container">
                  <h4
                    className={`section-title ${expandedSections[sectionTitle] ? 'expanded' : ''}`}
                    onClick={() => toggleSection(sectionTitle)}
                  >
                    {sectionTitle}
                    {selectedInspection.template?.name?.toLowerCase().includes('scored') && 
                      ` (${sectionData.totalPoints}/${sectionData.totalPossiblePoints} - ${calculateSectionPercentage(sectionData.totalPoints, sectionData.totalPossiblePoints)}%)`
                    }
                  </h4>
                  {expandedSections[sectionTitle] &&
                    sectionData.details.map((detail, idx) => (
                      <div key={idx} className="question-container">
                        <p className="question-text">
                          <strong>Question:</strong> {detail.question_text}
                        </p>
                        <p className="response-text">
                          <strong>Response:</strong> {detail.response_text}
                          {selectedInspection.template?.name?.toLowerCase().includes('scored') && (
                            <span className="question-score">
                              {` (${detail.points_earned || 0}/${detail.points_possible || 0} points)`}
                            </span>
                          )}
                        </p>
                        {detail.response_comments && (
                          <p className="comments-text">
                            <strong>Comments:</strong> {detail.response_comments}
                          </p>
                        )}
                        {detail.response_image_url && (
                          <img
                            src={detail.response_image_url}
                            alt="Response"
                            className="response-image"
                          />
                        )}
                      </div>
                    ))}
                </div>
              )
            )
          ) : (
            <p>No responses found for this inspection.</p>
          )}

          <div className="modal-footer">
            <DownloadPDFButton inspection={selectedInspection} />
            <button
              className="button close-button"
              onClick={() => setSelectedInspection(null)}
            >
              Close
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default PreviousInspections;
import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import '../styles/CreateTemplate.css';

const Modal = ({ isOpen, type, message, onClose }) => {
  if (!isOpen) return null;

  const getStyles = () => {
    switch (type) {
      case 'error': return 'text-red-600';
      case 'success': return 'text-green-600';
      default: return 'text-gray-600';
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-sm w-full mx-4">
        <div className={`text-lg font-semibold mb-2 ${getStyles()}`}>
          {type === 'error' ? 'Error' : type === 'success' ? 'Success' : 'Loading'}
        </div>
        <div className="mb-4">{message}</div>
        {type !== 'loading' && (
          <button onClick={onClose} className="bg-gray-200 px-4 py-2 rounded hover:bg-gray-300">
            Close
          </button>
        )}
        {type === 'loading' && (
          <div className="flex justify-center">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
          </div>
        )}
      </div>
    </div>
  );
};
const CreateTemplate = () => {
  const OPTION_CHOICES = ['Yes', 'No', 'Every time', 'Sometimes', 'Never', 'Custom'];

  const getDefaultSection = (type = 'standard') => ({
    title: '',
    scoreAllocation: 0,
    questions: [{
      text: '',
      type: type === 'scored' ? 'multiple_choice' : 'text',
      allow_photo: false,
      allow_comments: false,
      photo_required: false,
      description: '',
      options: type === 'scored' ? [{ text: OPTION_CHOICES[0], points: 0, isCustom: false }] : [],
      customOption: ''
    }]
  });

  // State declarations
  const [templateName, setTemplateName] = useState('');
  const [description, setDescription] = useState('');
  const [templateType, setTemplateType] = useState('standard');
  const [sections, setSections] = useState([getDefaultSection()]);
  const [templates, setTemplates] = useState([]);
  const [editingTemplateId, setEditingTemplateId] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    fetchTemplates();
  }, []);

  useEffect(() => {
    if (templateType === 'scored') {
      setSections(sections.map(section => ({
        ...section,
        questions: section.questions.map(question => ({
          ...question,
          type: 'multiple_choice',
          options: question.options.length ? question.options : [
            { text: OPTION_CHOICES[0], points: 0, isCustom: false }
          ]
        }))
      })));
    }
  }, [templateType]);

  // Event Handlers
  const handleTemplateTypeChange = (value) => {
    setTemplateType(value);
    if (value === 'scored') {
      setSections([getDefaultSection('scored')]);
    }
  };

  const handleSectionChange = (index, field, value) => {
    const newSections = [...sections];
    if (field === 'scoreAllocation') {
      value = parseInt(value) || 0;
    }
    newSections[index][field] = value;
    setSections(newSections);
  };

  const handleQuestionChange = (sectionIndex, questionIndex, field, value) => {
    const newSections = [...sections];
    newSections[sectionIndex].questions[questionIndex][field] = value;
    setSections(newSections);
  };

  const handleOptionChange = (sectionIndex, questionIndex, optionIndex, field, value) => {
    const newSections = [...sections];
    const question = newSections[sectionIndex].questions[questionIndex];
    const option = question.options[optionIndex];
    
    if (field === 'points') {
      option.points = parseInt(value) || 0;
    } else if (field === 'text') {
      if (value === 'Custom') {
        option.isCustom = true;
        option.text = 'Custom';
      } else if (option.isCustom) {
        question.customOption = value;
        option.text = value;
      } else {
        option.isCustom = false;
        option.text = value;
      }
    }
    
    setSections(newSections);
  };

  const removeOptionFromQuestion = (sectionIndex, questionIndex, optionIndex) => {
    const newSections = [...sections];
    const question = newSections[sectionIndex].questions[questionIndex];
    
    if (question.options.length > 1) {
      question.options = question.options.filter((_, index) => index !== optionIndex);
      setSections(newSections);
    } else {
      setError('Cannot remove the last option');
    }
  };

  const addSection = () => {
    setSections([...sections, getDefaultSection(templateType)]);
  };

  const addQuestion = (sectionIndex) => {
    const newSections = [...sections];
    newSections[sectionIndex].questions.push({
      text: '',
      type: templateType === 'scored' ? 'multiple_choice' : 'text',
      allow_photo: false,
      allow_comments: false,
      photo_required: false,
      description: '',
      options: templateType === 'scored' ? [
        { text: OPTION_CHOICES[0], points: 0, isCustom: false }
      ] : [],
      customOption: ''
    });
    setSections(newSections);
  };

  const removeSection = (sectionIndex) => {
    if (sections.length > 1) {
      const newSections = sections.filter((_, index) => index !== sectionIndex);
      setSections(newSections);
    } else {
      setError('Cannot remove the last section');
    }
  };

  const removeQuestion = (sectionIndex, questionIndex) => {
    const newSections = [...sections];
    if (newSections[sectionIndex].questions.length > 1) {
      newSections[sectionIndex].questions = newSections[sectionIndex].questions.filter(
        (_, index) => index !== questionIndex
      );
      setSections(newSections);
    } else {
      setError('Cannot remove the last question');
    }
  };

  const addOptionToQuestion = (sectionIndex, questionIndex) => {
    const newSections = [...sections];
    const question = newSections[sectionIndex].questions[questionIndex];
    const usedOptions = new Set(question.options.map(opt => opt.text));
    const availableOption = OPTION_CHOICES.find(choice => !usedOptions.has(choice));
    
    if (availableOption) {
      question.options.push({
        text: availableOption,
        points: 0,
        isCustom: availableOption === 'Custom'
      });
      setSections(newSections);
    }
  };
  // CRUD Operations
  const fetchTemplates = async () => {
    try {
      const { data, error } = await supabase.from('inspection_templates').select('*');
      if (error) throw error;
      setTemplates(data);
    } catch (error) {
      setError('Failed to fetch templates: ' + error.message);
      console.error(error);
    }
  };

  const handleEdit = async (templateId) => {
    try {
      const { data: templateData, error } = await supabase
        .from('inspection_templates')
        .select(`
          id, name, description, template_type,
          sections (
            id, title, score_allocation,
            questions (
              id, text, type, allow_photo, allow_comments, photo_required, description, options
            )
          )
        `)
        .eq('id', templateId)
        .single();

      if (error) throw error;

      setTemplateName(templateData.name);
      setDescription(templateData.description);
      setTemplateType(templateData.template_type || 'standard');
      setSections(
        templateData.sections.map((section) => ({
          title: section.title,
          scoreAllocation: section.score_allocation || 0,
          questions: section.questions.map((q) => ({
            text: q.text,
            type: templateData.template_type === 'scored' ? 'multiple_choice' : q.type,
            allow_photo: q.allow_photo,
            allow_comments: q.allow_comments,
            photo_required: q.photo_required,
            description: q.description,
            options: templateData.template_type === 'scored' ? 
              (q.options?.length ? q.options : [{ text: OPTION_CHOICES[0], points: 0, isCustom: false }]) : [],
            customOption: q.options?.find(opt => opt.isCustom)?.text || ''
          })),
        }))
      );
      setEditingTemplateId(templateId);
    } catch (error) {
      setError('Failed to load template for editing: ' + error.message);
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    setError('');
    setSuccess('');
    
    try {
      const templateData = {
        name: templateName,
        description,
        template_type: templateType,
      };

      if (editingTemplateId) {
        const { error: templateError } = await supabase
          .from('inspection_templates')
          .update(templateData)
          .eq('id', editingTemplateId);

        if (templateError) throw templateError;

        await supabase
          .from('sections')
          .delete()
          .eq('template_id', editingTemplateId);

        // Create new sections and questions
        for (const section of sections) {
          const sectionData = {
            template_id: editingTemplateId,
            title: section.title,
            score_allocation: section.scoreAllocation,
          };

          const { data: sectionResponse, error: sectionError } = await supabase
            .from('sections')
            .insert([sectionData])
            .select();

          if (sectionError) throw sectionError;

          for (const question of section.questions) {
            const questionData = {
              section_id: sectionResponse[0].id,
              text: question.text,
              type: templateType === 'scored' ? 'multiple_choice' : question.type,
              allow_photo: question.allow_photo,
              allow_comments: question.allow_comments,
              photo_required: question.photo_required,
              description: question.description,
              options: templateType === 'scored' ? 
                question.options.map(opt => ({
                  ...opt,
                  text: opt.isCustom ? opt.text : opt.text
                })) : null,
            };

            const { error: questionError } = await supabase
              .from('questions')
              .insert([questionData]);

            if (questionError) throw questionError;
          }
        }

        setSuccess('Template updated successfully!');
      } else {
        const { data: template, error: templateError } = await supabase
          .from('inspection_templates')
          .insert([templateData])
          .select();

        if (templateError) throw templateError;

        const templateId = template[0].id;

        for (const section of sections) {
          const sectionData = {
            template_id: templateId,
            title: section.title,
            score_allocation: section.scoreAllocation,
          };

          const { data: sectionResponse, error: sectionError } = await supabase
            .from('sections')
            .insert([sectionData])
            .select();

          if (sectionError) throw sectionError;

          for (const question of section.questions) {
            const questionData = {
              section_id: sectionResponse[0].id,
              text: question.text,
              type: templateType === 'scored' ? 'multiple_choice' : question.type,
              allow_photo: question.allow_photo,
              allow_comments: question.allow_comments,
              photo_required: question.photo_required,
              description: question.description,
              options: templateType === 'scored' ? 
                question.options.map(opt => ({
                  ...opt,
                  text: opt.isCustom ? opt.text : opt.text
                })) : null,
            };

            const { error: questionError } = await supabase
              .from('questions')
              .insert([questionData]);

            if (questionError) throw questionError;
          }
        }

        setSuccess('Template created successfully!');
      }

      resetForm();
      fetchTemplates();
    } catch (error) {
      setError('Failed to submit template: ' + error.message);
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  const resetForm = () => {
    setTemplateName('');
    setDescription('');
    setTemplateType('standard');
    setSections([getDefaultSection()]);
    setEditingTemplateId(null);
    setError('');
    setSuccess('');
  };
  return (
    <div className="page-container">
      <div className="existing-templates-container">
        <h2 className="main-heading">Existing Template Listing</h2>
        <ul className="template-list">
          {templates.map((template) => (
            <li key={template.id} className="template-item">
              {template.name}
              <button className="btn-secondary" onClick={() => handleEdit(template.id)}>
                Edit
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className="create-template-container">
        <h2 className="main-heading">
          {editingTemplateId ? 'Edit Template' : 'Create New Template'}
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="input-field">
            <label>Template Type:</label>
            <select
              className="input-field"
              value={templateType}
              onChange={(e) => handleTemplateTypeChange(e.target.value)}
              disabled={editingTemplateId}
            >
              <option value="standard">Standard Template</option>
              <option value="scored">Scored Template</option>
            </select>
          </div>

          <div className="input-field">
            <label>Template Name:
              <input
                type="text"
                className="input-field"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
                required
              />
            </label>
          </div>

          <div className="input-field">
            <label>Description:
              <textarea
                className="input-field"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </label>
          </div>

          {sections.map((section, sectionIndex) => (
            <div key={sectionIndex} className="section-container">
              <div className="section-header">
                <h3>Section {sectionIndex + 1}</h3>
                <button
                  type="button"
                  className="btn-danger"
                  onClick={() => removeSection(sectionIndex)}
                >
                  Remove Section
                </button>
              </div>

              <input
                type="text"
                className="input-field"
                placeholder="Section Title"
                value={section.title}
                onChange={(e) => handleSectionChange(sectionIndex, 'title', e.target.value)}
                required
              />

              {templateType === 'scored' && (
                <div className="input-field">
                  <label>Score Allocation:
                    <input
                      type="number"
                      className="input-field"
                      value={section.scoreAllocation}
                      onChange={(e) => handleSectionChange(sectionIndex, 'scoreAllocation', e.target.value)}
                      min="0"
                      required
                    />
                  </label>
                </div>
              )}

              {section.questions.map((question, questionIndex) => (
                <div key={questionIndex} className="question-container">
                  <div className="question-header">
                    <h4>Question {questionIndex + 1}</h4>
                    <button
                      type="button"
                      className="btn-danger"
                      onClick={() => removeQuestion(sectionIndex, questionIndex)}
                    >
                      Remove Question
                    </button>
                  </div>

                  <input
                    type="text"
                    className="input-field"
                    placeholder="Question Text"
                    value={question.text}
                    onChange={(e) => handleQuestionChange(sectionIndex, questionIndex, 'text', e.target.value)}
                    required
                  />

                  <textarea
                    className="input-field"
                    placeholder="Question Description (Hover Text)"
                    value={question.description}
                    onChange={(e) => handleQuestionChange(sectionIndex, questionIndex, 'description', e.target.value)}
                  />

                  {templateType === 'scored' && (
                    <div className="options-container">
                      <h5>Answer Options</h5>
                      {question.options.map((option, optionIndex) => (
                        <div key={optionIndex} className="option-item">
                          {option.isCustom ? (
                            <input
                              type="text"
                              className="input-field custom-option-input"
                              placeholder="Enter custom option"
                              value={option.text === 'Custom' ? '' : option.text}
                              onChange={(e) => handleOptionChange(
                                sectionIndex,
                                questionIndex,
                                optionIndex,
                                'text',
                                e.target.value
                              )}
                            />
                          ) : (
                            <select
                              className="input-field option-select"
                              value={option.text}
                              onChange={(e) => handleOptionChange(
                                sectionIndex,
                                questionIndex,
                                optionIndex,
                                'text',
                                e.target.value
                              )}
                            >
                              {OPTION_CHOICES.map(choice => (
                                <option 
                                  key={choice} 
                                  value={choice}
                                  disabled={
                                    choice === 'Custom' ? false :
                                    question.options.some(opt => 
                                      opt.text === choice && opt !== option
                                    )
                                  }
                                >
                                  {choice}
                                </option>
                              ))}
                            </select>
                          )}
                          <input
                            type="number"
                            className="input-field points-input"
                            placeholder="Points"
                            value={option.points}
                            onChange={(e) => handleOptionChange(
                              sectionIndex,
                              questionIndex,
                              optionIndex,
                              'points',
                              e.target.value
                            )}
                            min="0"
                          />
                          <button
                            type="button"
                            className="btn-danger"
                            onClick={() => removeOptionFromQuestion(sectionIndex, questionIndex, optionIndex)}
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                      {question.options.length < OPTION_CHOICES.length && (
                        <button
                          type="button"
                          className="btn-primary"
                          onClick={() => addOptionToQuestion(sectionIndex, questionIndex)}
                        >
                          Add Option
                        </button>
                      )}
                    </div>
                  )}

                  {templateType !== 'scored' && (
                    <>
                      <label>
                        Allow Photo:
                        <input
                          type="checkbox"
                          checked={question.allow_photo}
                          onChange={(e) =>
                            handleQuestionChange(
                              sectionIndex,
                              questionIndex,
                              'allow_photo',
                              e.target.checked
                            )
                          }
                        />
                      </label>
                      <label>
                        Allow Comments:
                        <input
                          type="checkbox"
                          checked={question.allow_comments}
                          onChange={(e) =>
                            handleQuestionChange(
                              sectionIndex,
                              questionIndex,
                              'allow_comments',
                              e.target.checked
                            )
                          }
                        />
                      </label>
                      <label>
                        Photo Required:
                        <input
                          type="checkbox"
                          checked={question.photo_required}
                          onChange={(e) =>
                            handleQuestionChange(
                              sectionIndex,
                              questionIndex,
                              'photo_required',
                              e.target.checked
                            )
                          }
                        />
                      </label>
                    </>
                  )}
                  <div className="question-divider"></div>
                </div>
              ))}
              <button
                type="button"
                className="btn-primary"
                onClick={() => addQuestion(sectionIndex)}
              >
                Add Question
              </button>
            </div>
          ))}

          <button type="button" className="btn-primary" onClick={addSection}>
            Add Section
          </button>
          <div className="button-group">
            <button type="submit" className="btn-submit">
              {editingTemplateId ? 'Save' : 'Create Template'}
            </button>
            <button type="button" className="btn-cancel" onClick={resetForm}>
              Cancel
            </button>
          </div>
        </form>
      </div>

      {/* Modals */}
      <Modal
        isOpen={isSaving}
        type="loading"
        message="Please wait while we save your template..."
      />
      <Modal
        isOpen={!!error}
        type="error"
        message={error}
        onClose={() => setError('')}
      />
      <Modal
        isOpen={!!success}
        type="success"
        message={success}
        onClose={() => setSuccess('')}
      />
    </div>
  );
};

export default CreateTemplate;